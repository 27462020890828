import type { FC } from 'react';
import React, { useState, useEffect, Fragment, memo } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import AkButton from '@atlaskit/button/standard-button';
import ModalTransition from '@atlaskit/modal-dialog/modal-transition';
import Tooltip from '@atlaskit/tooltip/Tooltip';
import { LinkItem } from '@atlaskit/side-navigation';
import AkAddonIcon from '@atlaskit/icon/glyph/addon';
import EditFilledIcon from '@atlaskit/icon/glyph/edit-filled';
import { N500, N20, B50 } from '@atlaskit/theme/colors';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useRouteActions, useRouteUrl } from '@confluence/route-manager';
import { PageSegmentLoadEnd } from '@confluence/browser-metrics';
import { FallbackableImage } from '@confluence/fallbackable-image';
import { ForgeKeyboardShortcut, ForgeKeyboardShortcutVisualizer } from '@confluence/forge-ui';
import { LoadableAfterPaint, useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';
import {
	confluenceLocalStorageInstance as localStorage,
	keys as localStorageKeys,
} from '@confluence/storage-manager';
import { SpacePersistentCollapsibleSectionLoader } from '@confluence/collapsible-section/entry-points/SpacePersistentCollapsibleSectionLoader';
import { EmptySidebarCollapsibleSectionLoader } from '@confluence/collapsible-section/entry-points/EmptySidebarCollapsibleSectionLoader';
import { useRenderServerPlaceholder } from '@confluence/ssr-utilities';

import { SPACE_APPS_METRIC } from './perf.config';
import { isConnectApp, isForgeApp } from './space-apps-helpers';
import type { SpaceNavigationQuery_spaceSidebarLinks_main } from './__types__/SpaceNavigationQuery';
import { SpaceSidebarLinkType } from './__types__/SpaceNavigationQuery';

const i18n = defineMessages({
	title: {
		id: 'side-navigation.space-apps.header',
		defaultMessage: 'Apps',
		description: 'Header for a list of apps',
	},
	editButtonTooltip: {
		id: 'side-navigation.space-apps.edit.tooltip',
		defaultMessage: 'Reorder, show and hide apps',
		description: 'Tip describing what a edit button will do',
	},
	appsTooltip: {
		id: 'side-navigation.space-apps.tooltip',
		defaultMessage: 'Access apps in this space',
		description: 'Tooltip when hovering over space apps space sidebar collapsible section',
	},
	noAppsMessage: {
		id: 'side-navigation.space-apps.no-space-apps.message',
		defaultMessage: 'No apps in this space',
		description: 'Message when apps section is empty',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledFallbackableImage = styled(FallbackableImage)<typeof FallbackableImage>({
	width: '16px',
	height: '16px',
});

const CSSFn = ({ isSelected }) => ({
	minHeight: '36px',
	padding: `${token('space.100', '8px')} 10px ${token(
		'space.100',
		'8px',
	)} ${token('space.500', '40px')}`,
	'& span': {
		columnGap: token('space.100', '8px'),
	},
	backgroundColor: isSelected ? token('color.background.selected', B50) : 'transparent',
	'&:hover': {
		backgroundColor: isSelected
			? token('color.background.selected.hovered', '#cce0ff')
			: token('color.background.neutral.subtle.hovered', N20),
	},
	'&, &:visited, &:active, &:focus, &:hover': {
		color: isSelected ? token('color.text.selected', '#0c66e4') : token('color.text.subtle', N500),
		textDecoration: 'none',
	},
	'[data-item-elem-before=true], [data-item-elem-before=true] span ': {
		height: '16px',
		width: '16px',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const AllLinksWrapper = styled.ul({
	listStyleType: 'none',
	margin: 0,
	padding: 0,
	//eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& > li': {
		marginTop: '0',
	},
});

const MINIMUM_NUMBER_OF_DISPLAYED_LINKS = 2;

type AppLinksComponentProps = {
	links: SpaceNavigationQuery_spaceSidebarLinks_main[];
};

type LinkItemComponentProps = {
	link: SpaceNavigationQuery_spaceSidebarLinks_main;
	handleClickOnLinkItem: (appTitle: string | null) => void;
};

const LinkItemComponent: FC<LinkItemComponentProps> = ({ link, handleClickOnLinkItem }) => {
	const isMatchingCurrentUrlArgs = {
		selector: (url: string | undefined) => url === link.url,
	};
	const matchesUrl = useRouteUrl(isMatchingCurrentUrlArgs);
	return (
		<LinkItem
			key={link.id}
			// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
			cssFn={CSSFn}
			iconBefore={
				<StyledFallbackableImage
					data-vc="space-apps-link-icon"
					src={link?.icon?.path}
					// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
					fallback={AkAddonIcon}
				/>
			}
			isSelected={!!(link.url && matchesUrl)}
			href={link.url || undefined}
			onClick={() => handleClickOnLinkItem(link.title)}
		>
			{link.title}
		</LinkItem>
	);
};

const AppLinksComponent: FC<AppLinksComponentProps> = ({ links }) => {
	const renderServerPlaceholder = useRenderServerPlaceholder();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { push } = useRouteActions();

	const [isShowingAll] = useState(
		localStorage?.getItemAsBoolean(localStorageKeys.SPACENAV_APPS_SHOW_ALL),
	);

	const handleClickOnLinkItem = (appTitle: string | null) => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				source: 'sidebar',
				actionSubjectId: 'appLink',
				attributes: {
					appTitle,
				},
			},
		}).fire();
	};

	useEffect(() => {
		localStorage?.setItem(localStorageKeys.SPACENAV_APPS_SHOW_ALL, isShowingAll);
	}, [isShowingAll]);

	const linkItems = links.map((link) => {
		const linkItem = (
			<li>
				<LinkItemComponent link={link} handleClickOnLinkItem={handleClickOnLinkItem} />
			</li>
		);

		return link.type === SpaceSidebarLinkType.FORGE && !renderServerPlaceholder ? (
			<ForgeKeyboardShortcutVisualizer key={link.id} module={link as any}>
				{linkItem}
			</ForgeKeyboardShortcutVisualizer>
		) : (
			linkItem
		);
	});

	const forgeKeyboardShortcuts = links
		.filter((link) => link.type === SpaceSidebarLinkType.FORGE && link.url)
		.map((link) => (
			<ForgeKeyboardShortcut
				key={link.id}
				module={link as any}
				action={() => push(link.url ?? '')}
			/>
		));

	if (linkItems.length <= MINIMUM_NUMBER_OF_DISPLAYED_LINKS) {
		return (
			<Fragment>
				{linkItems}
				{!renderServerPlaceholder && forgeKeyboardShortcuts}
			</Fragment>
		);
	}

	return (
		<Fragment>
			{linkItems}
			{!renderServerPlaceholder && forgeKeyboardShortcuts}
		</Fragment>
	);
};

const LazySpaceAppsConfiguration = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-SpaceAppsConfiguration" */ './SpaceAppsConfiguration'
			)
		).SpaceAppsConfiguration,
});

type SpaceAppsProps = {
	isSpaceAdmin: boolean;
	allAppLinks: any[];
	allVisibleAppLinks: any[];
	spaceKey: string;
};

export const Nav3SpaceApps: FC<SpaceAppsProps> = memo(
	({ isSpaceAdmin, allAppLinks, allVisibleAppLinks, spaceKey }) => {
		const { formatMessage } = useIntl();
		const [isOpenAppsConfiguration, setOpenAppsConfiguration] = useState(false);
		const noVisibleAppLinks = allVisibleAppLinks.length == 0;
		const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();

		if (allAppLinks.length == 0 || (noVisibleAppLinks && !isSpaceAdmin)) {
			return null;
		}

		const pageSegmentLoadCustomData = {
			connectApps: allVisibleAppLinks.filter((a) => isConnectApp(a)).length,
			forgeApps: allVisibleAppLinks.filter((a) => isForgeApp(a)).length,
		};

		const spaceAppsContent = (
			<SpacePersistentCollapsibleSectionLoader
				renderAfterIconButton={() =>
					isSpaceAdmin ? (
						<Tooltip content={<FormattedMessage {...i18n.editButtonTooltip} />} position="bottom">
							<AkButton
								id="space-apps-collapsible-section-header-button"
								appearance="subtle"
								spacing="none"
								onClick={() => setOpenAppsConfiguration(true)}
								//  eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
								iconBefore={<EditFilledIcon label="Edit apps" />}
							/>
						</Tooltip>
					) : null
				}
				headerTitle={formatMessage(i18n.title)}
				sectionName="apps"
				tooltipContent={formatMessage(i18n.appsTooltip)}
			>
				{noVisibleAppLinks ? (
					<EmptySidebarCollapsibleSectionLoader>
						<FormattedMessage {...i18n.noAppsMessage} />
					</EmptySidebarCollapsibleSectionLoader>
				) : (
					<AllLinksWrapper>
						<AppLinksComponent links={allVisibleAppLinks} />
					</AllLinksWrapper>
				)}
			</SpacePersistentCollapsibleSectionLoader>
		);

		return (
			<div data-vc="space-apps" data-testid="space-apps" {...ssrPlaceholderIdProp}>
				<PageSegmentLoadEnd
					key={spaceKey}
					metric={SPACE_APPS_METRIC}
					customData={pageSegmentLoadCustomData}
				/>
				{spaceAppsContent}
				<ModalTransition>
					{isOpenAppsConfiguration ? (
						<LazySpaceAppsConfiguration
							links={allAppLinks}
							spaceKey={spaceKey}
							onCancel={() => setOpenAppsConfiguration(false)}
							onSave={() => setOpenAppsConfiguration(false)}
						/>
					) : (
						<Fragment />
					)}
				</ModalTransition>
			</div>
		);
	},
);
